<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí Product Category</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDiaLogCategory"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Thêm category</span>-->
<!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Display Name</th>
                    <!--                  <th scope="col">Total Programme</th>-->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(category, i) in product_categories">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ category.display_name }}
                        </p>
                      </td>
                      <!--                    <td>-->
                      <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{category.programmes.length}}</p>-->
                      <!--                    </td>-->
                      <td>
                        <div style="display: flex">
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-primary btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="editCategory(i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
                          <!--                        <v-tooltip top>-->
                          <!--                          <template v-slot:activator="{ on, attrs }">-->
                          <!--                            <button class="btn btn-icon btn-light-warning btn-sm ml-4" v-bind="attrs" v-on="on" @click="openDialogReason(i)">-->
                          <!--                                <span class="svg-icon svg-icon-md svg-icon-primary">-->
                          <!--                                  <v-icon color="">mdi-lightbulb-on-outline</v-icon>-->
                          <!--                                </span>-->
                          <!--                            </button>-->
                          <!--                          </template>-->
                          <!--                          <span>Reasons to buy</span>-->
                          <!--                        </v-tooltip>-->

                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'ProductCategoryCourse',
                                  params: { id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-warning btn-sm ml-4 btn-light-warning-custom-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-book-open-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Cấu hình Level - Course</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'StudyPlanList',
                                  params: { id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-4"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-calendar</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Cấu hình Study Plan</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'ProductCategoryBanner',
                                  params: { id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-green btn-sm ml-4"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-tooltip-image-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Banner</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'LevelSelector',
                                  params: { id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-4 btn-purple"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-file-table-box-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Level Selector</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'ProductCategoryTarget',
                                  params: { id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-4 btn-light-success-custom"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-bullseye-arrow</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Target</span>
                          </v-tooltip>

                          <v-tooltip top v-if="category.id == product_category_constant.PREPTALK_ENGLISH">
                            <template v-slot:activator="{ on, attrs }">
                              <router-link :to="{name:'ProductCategoryTopVsr', params: {id: category.id}}" target="_blank">
                                <button class="btn btn-icon btn-light-success btn-sm ml-4" v-bind="attrs" v-on="on">
                                  <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <v-icon color="">mdi-bookshelf</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Topic VSR PTE</span>
                          </v-tooltip>

                          <!--                        <v-tooltip top>-->
                          <!--                          <template v-slot:activator="{ on, attrs }">-->
                          <!--                            <router-link :to="{name:'SubCategoryList', params: {id: category.id}}" target="_blank">-->
                          <!--                              <button class="btn btn-icon btn-light-success btn-sm ml-4" v-bind="attrs" v-on="on">-->
                          <!--                                <span class="svg-icon svg-icon-md svg-icon-primary">-->
                          <!--                                  <v-icon color="">mdi-subtitles-outline</v-icon>-->
                          <!--                                </span>-->
                          <!--                              </button>-->
                          <!--                            </router-link>-->
                          <!--                          </template>-->
                          <!--                          <span>Sub Category</span>-->
                          <!--                        </v-tooltip>-->
                          <!--                        <v-tooltip top>-->
                          <!--                          <template v-slot:activator="{ on, attrs }">-->
                          <!--                            <router-link :to="{name:'ProductCategoryUSP', params: {id: category.id}}" target="_blank">-->
                          <!--                              <button class="btn btn-icon btn-light-success btn-sm ml-4 btn-light-warning-custom" v-bind="attrs" v-on="on">-->
                          <!--                                <span class="svg-icon svg-icon-md svg-icon-primary">-->
                          <!--                                  <v-icon color="">mdi-alpha-u-circle</v-icon>-->
                          <!--                                </span>-->
                          <!--                              </button>-->
                          <!--                            </router-link>-->
                          <!--                          </template>-->
                          <!--                          <span>Cấu hình USP</span>-->
                          <!--                        </v-tooltip>-->

<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-danger btn-sm ml-4"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="deleteCategory(category.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color="">mdi-delete</v-icon>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Delete</span>-->
<!--                          </v-tooltip>-->

                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogCategory"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Product Category</span
              >
              <span class="headline" v-else>Edit Product Category</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCategory = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="p-0 m-0"></v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="language"
                        row
                        class="mb-0 mt-0 pb-0"
                        dense
                      >
                        <v-radio label="Việt Nam" value="vi"></v-radio>
                        <v-radio label="English" value="en"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <label class="text-h6"
                        >Tên category
                        <span
                          class="red--text text-body"
                          v-if="language === 'vi'"
                          >(*)</span
                        >:
                      </label>
                    </v-col>
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'vi'"
                      ><v-text-field
                        dense
                        v-model="name_input.vi"
                        filled
                      ></v-text-field
                    ></v-col>
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'en'"
                      ><v-text-field
                        dense
                        v-model="name_input.en"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Slug
                        <span class="red--text text-body">(*)</span>:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="slug_name_input"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Tag line
                        <span class="red--text text-body">(*)</span>:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="tag_line_input"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Banner:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <!--                      <ImageCompress :done="getFileLogo"></ImageCompress>-->
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="banner_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="banner_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mô tả:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Teachers:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <div class="d-flex">
                        <v-autocomplete
                          v-model="teacher_selected"
                          :items="teacher_unselect"
                          item-value="id"
                          item-text="name"
                          filled
                          multiple
                          chips
                        >
                        </v-autocomplete>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-2 font-weight-bold"
                          outlined
                          small
                          @click="saveTeacher()"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Thêm
                        </v-btn>
                      </div>
                      <p v-if="teacher_input.length">
                        (Hãy di chuyển button nếu muốn sắp xếp lại)
                      </p>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pt-2 pb-2"
                      >
                        <p
                          v-if="teacher_input.length === 0"
                          class="text-center"
                        >
                          no data
                        </p>
                        <template v-if="teacher_input.length">
                          <draggable :list="teacher_input">
                            <template v-for="(teacher, i) in teacher_input">
                              <v-btn
                                style="background-color: #3949ab; color: white"
                                dense
                                class="ml-2 mt-1 mb-1"
                                :key="i"
                              >
                                {{ i + 1 }} - {{ teacher.name }}
                                <v-icon
                                  dense
                                  color="white"
                                  class="ml-1"
                                  dark
                                  @click="delTeacher(i)"
                                  >mdi-close</v-icon
                                >
                              </v-btn>
                            </template>
                          </draggable>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Programmes:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <div class="d-flex">
                        <v-autocomplete
                          v-model="programme_selected"
                          :items="programme_unselect"
                          item-value="id"
                          item-text="name.vi"
                          filled
                          multiple
                          chips
                        >
                        </v-autocomplete>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-2 font-weight-bold"
                          outlined
                          small
                          @click="saveProgramme()"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Thêm
                        </v-btn>
                      </div>
                      <p v-if="programme_input.length">
                        (Hãy di chuyển button nếu muốn sắp xếp lại)
                      </p>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pt-2 pb-2"
                      >
                        <p
                          v-if="programme_input.length === 0"
                          class="text-center"
                        >
                          no data
                        </p>
                        <template v-if="programme_input.length">
                          <draggable :list="programme_input">
                            <template v-for="(programme, i) in programme_input">
                              <v-btn
                                style="background-color: #039be5; color: white"
                                dense
                                class="ml-2 mt-1 mb-1"
                                :key="i"
                              >
                                {{ i + 1 }} - {{ programme.name.vi }}
                                <v-icon
                                  dense
                                  color="white"
                                  class="ml-1"
                                  dark
                                  @click="delProgramme(i)"
                                  >mdi-close</v-icon
                                >
                              </v-btn>
                            </template>
                          </draggable>
                        </template>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Programmes (gift):</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <div class="d-flex">
                        <v-autocomplete
                          v-model="programme_gift_selected"
                          :items="programme_unselect"
                          item-value="id"
                          item-text="name.vi"
                          filled
                          multiple
                          chips
                        >
                        </v-autocomplete>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-2 font-weight-bold"
                          outlined
                          small
                          @click="saveProgrammeGift()"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Thêm
                        </v-btn>
                      </div>
                      <p v-if="programme_gift_input.length">
                        (Hãy di chuyển button nếu muốn sắp xếp lại)
                      </p>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pt-2 pb-2"
                      >
                        <p
                          v-if="programme_gift_input.length === 0"
                          class="text-center"
                        >
                          no data
                        </p>
                        <template v-if="programme_gift_input.length">
                          <draggable :list="programme_gift_input">
                            <template
                              v-for="(programme, i) in programme_gift_input"
                            >
                              <v-btn
                                style="background-color: #ffab40; color: white"
                                dense
                                class="ml-2 mt-1 mb-1"
                                :key="i"
                              >
                                {{ i + 1 }} - {{ programme.name.vi }}
                                <v-icon
                                  dense
                                  color="white"
                                  class="ml-1"
                                  dark
                                  @click="delProgrammeGift(i)"
                                  >mdi-close</v-icon
                                >
                              </v-btn>
                            </template>
                          </draggable>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditCategory(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditCategory(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogCategory = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <ReasonToBuy
      :show_dialog="dialogReason"
      @setDialog="(e) => (this.dialogReason = e)"
      @resetProductCategory="getAllProductCategory()"
      :data_reason="data_reason_to_buy"
      :category_id="category_id"
    ></ReasonToBuy>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import Editor from "../../../components/ckeditor5/Editor";
import ReasonToBuy from "@/view/pages/products/modal/ReasonToBuy";
import draggable from "vuedraggable";
import constantTestBuilder from "../tests/mixins/Support/constantTestBuilder";
export default {
  name: "CategoryList",
  components: {
    draggable,
    ReasonToBuy,
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  mixins: [
    constantTestBuilder,
  ],
  data() {
    return {
      is_call_api: false,
      dialogCategory: false,
      product_categories: [],
      category_id: null,
      type_form: null,
      name_input: {
        en: "",
        vi: "",
      },
      slug_name_input: "",
      language: "vi",
      description_input: "",
      tag_line_input: "",
      dialogReason: false,
      data_reason_to_buy: [],
      teacher_tags: [],
      teacher_unselect: [],
      teacher_selected: [],
      teacher_input: [],
      //programmes
      programme_tags: [],
      programme_unselect: [],
      programme_selected: [],
      programme_input: [],
      // programme gift
      programme_gift_selected: [],
      programme_gift_input: [],

      banner_data: null,
      banner_file: null,
    };
  },
  created() {
    this.getAllProductCategory();
    this.getProgrammeLimit();
    this.getAllTeacher();
  },
  methods: {
    async getAllTeacher() {
      let vm = this;
      await ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          vm.teacher_tags = response.data;
        }
      });
    },
    async getAllProductCategory() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/product-category");
        if (res.status === 200) {
          vm.product_categories = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programme_tags = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openDiaLogCategory() {
      this.type_form = "add";
      this.language = "vi";
      this.name_input = {
        en: "",
        vi: "",
      };
      this.slug_name_input = "";
      this.description_input = "";
      this.teacher_unselect = JSON.parse(JSON.stringify(this.teacher_tags));
      this.teacher_input = [];
      this.programme_unselect = JSON.parse(JSON.stringify(this.programme_tags));
      this.programme_input = [];
      this.programme_gift_input = [];
      this.banner_data = null;
      this.banner_file = null;
      this.tag_line_input = "";
      this.dialogCategory = true;
    },
    editCategory(index) {
      let data = this.product_categories[index];
      this.language = "vi";
      this.name_input = JSON.parse(data.name);
      this.category_id = data.id.toString();
      this.slug_name_input = data.slug_name;
      this.tag_line_input = data.tag_line;
      this.description_input =
        data.description === null ? "" : data.description;
      this.teacher_unselect = JSON.parse(JSON.stringify(this.teacher_tags));
      this.teacher_input = JSON.parse(JSON.stringify(data.teachers));
      this.programme_unselect = JSON.parse(JSON.stringify(this.programme_tags));
      this.programme_input = JSON.parse(JSON.stringify(data.programmes));
      this.programme_gift_input = JSON.parse(
        JSON.stringify(data.programme_gift)
      );
      this.banner_data = data.banner;
      this.banner_file = null;
      this.type_form = "update";
      this.dialogCategory = true;
    },
    validateData() {
      let flat = true;
      if (this.name_input.vi === "" || this.name_input.vi == null) {
        this.$toasted.error("Chưa nhập tên category (Tiếng việt)!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.slug_name_input === "" || this.slug_name_input == null) {
        this.$toasted.error("Chưa nhập slug name!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.tag_line_input === "" || this.tag_line_input == null) {
        this.$toasted.error("Chưa nhập tag line!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditCategory(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("name", JSON.stringify(this.name_input));
      if (this.teacher_input.length)
        formData.append(
          "teacher_ids",
          this.teacher_input.map((e) => e.id)
        );
      if (this.programme_input.length)
        formData.append(
          "programme_ids",
          this.programme_input.map((e) => e.id)
        );
      if (this.programme_gift_input.length)
        formData.append(
          "programme_gift_ids",
          this.programme_gift_input.map((e) => e.id)
        );
      if (this.description_input)
        formData.append("description", this.description_input);
      if (this.slug_name_input !== "" && this.slug_name_input != null)
        formData.append("slug_name", this.slug_name_input);
      if (this.tag_line_input !== "" && this.tag_line_input != null)
        formData.append("tag_line", this.tag_line_input);
      if (this.banner_file != null) formData.append("banner", this.banner_file);

      // let data = {
      //   name: JSON.stringify(this.name_input),
      // }
      // if (this.teacher_input.length) data.teacher_ids = this.teacher_input.map(e => e.id);
      // if (this.programme_input.length) data.programme_ids = this.programme_input.map(e => e.id);
      // if (this.programme_gift_input.length) data.programme_gift_ids = this.programme_gift_input.map(e => e.id);
      // if (this.description_input) data.description = this.description_input;
      // if (this.slug_name_input !== "" && this.slug_name_input != null) {
      //   data.slug_name = this.slug_name_input;
      // }
      this.is_call_api = true;
      if (type === 1) {
        await ApiService.post("prep-app/product-category", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllProductCategory();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogCategory = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        await ApiService.post(
          "prep-app/product-category/" + this.category_id,
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllProductCategory();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogCategory = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteCategory(category_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/product-category/" + category_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllProductCategory();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogReason(index) {
      let data = this.product_categories[index];
      this.category_id = data.id.toString();
      this.data_reason_to_buy = JSON.parse(JSON.stringify(data.reasons_to_buy));
      this.dialogReason = true;
    },
    saveTeacher() {
      for (let i = 0; i < this.teacher_selected.length; i++) {
        let obj_teacher = this.teacher_unselect.filter(
          (e) => e.id === this.teacher_selected[i]
        )[0];
        if (
          this.teacher_input.some((ele) => ele.id === this.teacher_selected[i])
        ) {
          this.$toasted.info(
            "Giáo viên " + obj_teacher.name + " đã được thêm trước đó",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
        } else {
          this.teacher_input.push(
            this.teacher_unselect.filter(
              (e) => e.id === this.teacher_selected[i]
            )[0]
          );
        }
      }
      this.teacher_selected = [];
    },
    delTeacher(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.teacher_input.splice(index, 1);
        }
      });
    },
    saveProgramme() {
      for (let i = 0; i < this.programme_selected.length; i++) {
        let obj_programme = this.programme_unselect.filter(
          (e) => e.id === this.programme_selected[i]
        )[0];
        if (
          this.programme_input.some(
            (ele) => ele.id === this.programme_selected[i]
          )
        ) {
          this.$toasted.info(
            "Chương trình " + obj_programme.name.vi + " đã được thêm trước đó",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
        } else {
          this.programme_input.push(obj_programme);
        }
      }
      this.programme_selected = [];
    },
    delProgramme(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.programme_input.splice(index, 1);
        }
      });
    },
    saveProgrammeGift() {
      for (let i = 0; i < this.programme_gift_selected.length; i++) {
        let obj_programme = this.programme_unselect.filter(
          (e) => e.id === this.programme_gift_selected[i]
        )[0];
        if (
          this.programme_gift_input.some(
            (ele) => ele.id === this.programme_gift_selected[i]
          )
        ) {
          this.$toasted.info(
            "Chương trình " + obj_programme.name.vi + " đã được thêm trước đó",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
        } else {
          this.programme_gift_input.push(obj_programme);
        }
      }
      this.programme_gift_selected = [];
    },
    delProgrammeGift(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.programme_gift_input.splice(index, 1);
        }
      });
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.banner_data = null;
        vm.banner_file = null;
        return;
      }
      vm.banner_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.banner_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped lang="scss">
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
.btn.btn-light-success-custom {
  color: #00e5ff;
  background-color: #c0f9ff;
  border-color: transparent;
}
.btn.btn-light-success-custom i {
  color: #00e5ff;
}
.btn.btn-light-success-custom:hover:not(.btn-text):not(:disabled):not(
    .disabled
  ),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #00e5ff;
}

.btn.btn-purple {
  color: #ba68c8;
  background-color: #f8f1fa;
  border-color: transparent;
}
.btn.btn-purple i {
  color: #ba68c8;
}
.btn.btn-purple:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #ba68c8;
}

.btn.btn-light-warning-custom {
  color: #757575;
  background-color: #e3e3e3;
  border-color: transparent;
}
.btn.btn-light-warning-custom i {
  color: #757575;
}
.btn.btn-light-warning-custom:hover:not(.btn-text):not(:disabled):not(
    .disabled
  ),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #757575;
}

.btn.btn-light-warning-custom-2 {
  color: #26a69a;
  background-color: #d8eeec;
  border-color: transparent;
}
.btn.btn-light-warning-custom-2 i {
  color: #26a69a;
}
.btn.btn-light-warning-custom-2:hover:not(.btn-text):not(:disabled):not(
    .disabled
  ),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #26a69a;
}

.btn.btn-green {
  color: #66bb6a;
  background-color: #e2f2e3;
  border-color: transparent;
}
.btn.btn-green i {
  color: #66bb6a;
}
.btn.btn-green:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #66bb6a;
}
</style>
