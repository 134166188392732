<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogReasonToBuy"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Reason to buy</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogReasonToBuy = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3" class="ml-8 p-0 mt-8" id="image-avatar">
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="avatar_data != null">
                        <v-img
                          :src="avatar_data"
                          max-width="160"
                          max-height="90"
                          class="rounded-lg mt-1"
                        ></v-img>
                      </template>
                    </v-col>
                    <v-col cols="5" class="ml-8 p-0 mt-8">
                      <v-textarea
                        v-model="content_input"
                        outlined
                        auto-grow
                        dense
                        rows="1"
                        class="text-body-1"
                      ></v-textarea>
                    </v-col>
                    <v-btn
                      color="cyan"
                      class="ma-2 mt-8 font-weight-bold"
                      outlined
                      small
                      @click="addReason()"
                    >
                      <v-icon small left>mdi-check</v-icon>
                      Lưu
                    </v-btn>
                    <v-btn
                      color="#E53935"
                      class="mt-8 font-weight-bold"
                      outlined
                      small
                      @click="resetReason"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Hủy
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <table
                    class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Avatar</th>
                        <th scope="col">Content</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(reason, i) in data_reason">
                        <tr :key="i">
                          <td scope="row">{{ i + 1 }}</td>
                          <td>
                            <v-img
                              :src="reason.avatar"
                              max-width="100"
                              max-height="70"
                              class="rounded-lg"
                            ></v-img>
                          </td>
                          <td>
                            <pre
                              style="font-family: Montserrat"
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >{{ reason.content }}</pre
                            >
                          </td>
                          <td>
                            <div style="display: flex">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-primary btn-sm"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editReason(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color=""
                                        >mdi-square-edit-outline</v-icon
                                      >
                                    </span>
                                  </button>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-danger btn-sm ml-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteReason(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color="">mdi-delete</v-icon>
                                    </span>
                                  </button>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="saveReason"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogReasonToBuy = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "ReasonToBuy",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    data_reason: {
      type: Array,
      default: () => [],
    },
    category_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      is_call_api: false,
      content_input: "",
      avatar_data: null,
      avatar_file: null,
      index_reason: null,
    };
  },
  computed: {
    dialogReasonToBuy: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogReasonToBuy(val) {
      if (val) {
        this.resetReason();
      } else {
        this.setDefaultLabelAvatar();
      }
    },
  },
  methods: {
    validateData() {
      let flat = true;
      if (this.content_input === "" || this.content_input == null) {
        this.$toasted.error("Chưa nhập nội dung!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    resetReason() {
      this.content_input = "";
      this.avatar_data = null;
      this.avatar_file = null;
      this.setDefaultLabelAvatar();
      this.index_reason = null;
    },
    async addReason() {
      let vm = this;
      let validateForm = this.validateData();
      if (!validateForm) {
        return;
      }

      let avatar_url = "";
      if (this.index_reason !== null) {
        avatar_url = this.avatar_data;
      }
      if (this.avatar_file) {
        let formData = new FormData();
        formData.append("file", this.avatar_file);
        vm.is_call_api = true;
        await ApiService.post(
          "prep-app/product-category/upload-image-reason",
          formData
        )
          .then(function (res) {
            if (res.status === 200) {
              avatar_url = res.data.url;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
      let objReason = {
        avatar: avatar_url,
        content: this.content_input,
      };
      if (this.index_reason !== null) {
        this.data_reason[this.index_reason] = objReason;
      } else {
        this.data_reason.push(objReason);
      }
      this.resetReason();
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_data = null;
        vm.avatar_file = null;
        vm.setDefaultLabelAvatar();
        return;
      }
      vm.avatar_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_data = e.target.result;
      };
      vm.setFileNameAvatar(file.name);
      reader.readAsDataURL(file);
    },
    async saveReason() {
      if (this.data_reason.length <= 0) {
        this.$toasted.error("Chưa nhập dữ liệu nào!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      let data = {
        reasons_to_buy: this.data_reason,
        category_id: this.category_id,
      };
      vm.is_call_api = true;
      await ApiService.post("prep-app/product-category/update-reason", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.is_call_api = false;
            vm.$emit("resetProductCategory");
            vm.$toasted.success("Cập nhập thành công !", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.dialogReasonToBuy = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    editReason(index) {
      let data = this.data_reason[index];
      this.avatar_file = null;
      this.avatar_data = data.avatar;
      this.content_input = data.content;
      this.index_reason = index;
    },
    deleteReason(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.data_reason.splice(index, 1);
        }
      });
    },
    setDefaultLabelAvatar() {
      // document.getElementById("image-avatar").firstChild.children[1].firstChild.textContent = "Chọn tệp tin hoặc cancel để xóa";
      let ele = document.getElementById("image-avatar");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
    setFileNameAvatar(name) {
      document.getElementById(
        "image-avatar"
      ).firstChild.children[1].firstChild.textContent = name;
    },
  },
};
</script>

<style scoped>
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
